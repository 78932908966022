// import React from "react";

// import FooterLogo from "../assets/images/logo-1-1.png";
// import qrCodeImage from '../assets/images/qr1.jpg';
// import footerBg from '../assets/images/footer-bg.png';
// import footerLogo from '../assets/images/IUNEW3-duoi.png';
// import footerShape from '../assets/images/footer-top-shape.png';
// import { Facebook, Twitter, Instagram, Pinterest, Telephone, GeoAlt, Globe,  Building,FileText } from 'react-bootstrap-icons';

// const Footer = () => {
//   return (

// <div>

// <div className="shape-img subscribe-wrap">
//   <img src={footerShape} alt="footer shape" className="img-fluid" />
//   <div className="container">
//     <div className="row justify-content-center">
//       <div className="col-md-6">
//         <div className="w-100 position-relative">
          
//         </div>
//       </div>
//     </div>
//   </div>
// </div>



// <footer className="footer-section">
//       {/* Phần shape image */}
//       <div className="shape-img subscribe-wrap">
//         <img src={footerShape} alt="footer shape" className="img-fluid" />
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-md-6">
//               <div className="w-100 position-relative">
               
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Phần footer */}
// <div
//   className="footer-top pt-150 background-img-2"
//   style={{ background: `url(${footerBg}) no-repeat center top / cover`, paddingTop: '100px' }}
// >
//   <div className="container">
//     <div className="row">
//       {/* Logo and Social Icons Section */}
//       <div className="col-md-6 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
//         <div className="footer-logo-wrap text-white d-flex flex-column align-items-center align-items-md-start"> {/* Flexbox for better alignment */}
//           {/* <img src={footerLogo} alt="footer logo" width="220px" className="img-fluid mb-3" /> */}
//           <div
//   style={{
//     position: 'relative',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     width: '250px', // Adjust the size of the circular area
//     height: '250px', // Adjust the size of the circular area
//     background: '#fff', // White background for the concave effect
//     borderRadius: '50%', // Make it circular
//     margin: '0 auto', // Center the element horizontally
//     boxShadow: '0 0 30px rgba(0, 0, 0, 0.2)', // Optional: Add shadow for depth
//   }}
// >
//   <img
//     src={footerLogo}
//     alt="footer logo"
//     style={{
//       width: '220px', // Adjust the size of the logo as needed
//       zIndex: 1, // Ensure the logo is on top of the white background
//     }}
//   />
// </div>

         
//         </div>
//       </div>

//       {/* About Us Section */}
//       <div className="col-md-6">
//         <div className="footer-about-wrap text-white" style={{ paddingLeft: '50px' }}> {/* Adjust padding as needed */}
//         <h5 className="mb-3 text-white" style={{ fontWeight: 'bold' }}>VỀ CHÚNG TÔI</h5>
//           <ul className="list-unstyled support-list">
//   <li className="mb-2 d-flex align-items-start"> {/* Giảm khoảng cách từ mb-3 xuống mb-2 */}
//     <Building className="me-2" size={22} style={{ flexShrink: 0 }} /> {/* Giảm "me-3" xuống "me-2" để khoảng cách giữa icon và chữ nhỏ hơn */}
//     <span style={{ fontSize: '16px', paddingLeft: '8px' }}>Công Ty TNHH Nối Group</span> {/* Giảm padding-left */}
//   </li>
//   <li className="mb-2 d-flex align-items-start"> {/* Giảm khoảng cách từ mb-3 xuống mb-2 */}
//     <FileText className="me-2" size={22} style={{ flexShrink: 0 }} />
//     <span style={{ fontSize: '16px', paddingLeft: '8px' }}>MST: 0318379051</span>
//   </li>
//   <li className="mb-2 d-flex align-items-start"> {/* Giảm khoảng cách từ mb-3 xuống mb-2 */}
//     <GeoAlt className="me-2" size={22} style={{ flexShrink: 0 }} />
//     <span style={{ fontSize: '16px', paddingLeft: '8px' }}>Tầng 08, Toà nhà Pearl Plaza, Số 561A Điện Biên Phủ, P.25, Q.Bình Thạnh, TP.HCM</span>
//   </li>
//   <li className="mb-2 d-flex align-items-start"> {/* Giảm khoảng cách từ mb-3 xuống mb-2 */}
//     <Telephone className="me-2" size={26} style={{ flexShrink: 0 }} />
//     <span style={{ fontSize: '16px', paddingLeft: '8px' }}>0866428869</span>
//   </li>
//   <li className="mb-2 d-flex align-items-start"> {/* Giảm khoảng cách từ mb-3 xuống mb-2 */}
//     <Globe className="me-2" size={22} style={{ flexShrink: 0 }} />
//     <span style={{ fontSize: '16px', paddingLeft: '8px' }}>
//       <a href="https://noi.dating/" style={{ color: '#fff' }}>www.noi.dating</a>
//     </span>
//   </li>
// </ul>



//         </div>
//       </div>
//     </div>
//   </div>

//   {/* Footer Bottom Section */}
//   <div className="footer-bottom border-gray-light mt-5 py-3">
//     <div className="container">
//       <div className="row justify-content-center">
//         <div className="col-md-12">
//           <div className="copyright-wrap small-text text-center">
//             <p className="mb-0 text-white">© 2024 - Nối Group</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// </footer>




// </div>



//   );
// };

// export default Footer;


import React, { useState } from "react";
import FooterLogo from "../assets/images/logo-1-1.png";
import qrCodeImage from '../assets/images/qr1.jpg';
import footerBg from '../assets/images/footer-bg.png';
import footerLogo from '../assets/images/IUNEW3-duoi.png';
import footerShape from '../assets/images/footer-top-shape.png';
import { Facebook, Twitter, Instagram, Pinterest, Telephone, GeoAlt, Globe, Building, FileText } from 'react-bootstrap-icons';
import { Modal, Button } from 'react-bootstrap';

const Footer = () => {
  // State quản lý hiển thị modal
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsOfService, setShowTermsOfService] = useState(false);

  // Hàm đóng/mở modal
  const handlePrivacyPolicy = () => setShowPrivacyPolicy(!showPrivacyPolicy);
  const handleTermsOfService = () => setShowTermsOfService(!showTermsOfService);

  return (
    <div>
      <div className="shape-img subscribe-wrap">
        <img src={footerShape} alt="footer shape" className="img-fluid" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="w-100 position-relative"></div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer-section">
        <div className="footer-top pt-150 background-img-2"
          style={{ background: `url(${footerBg}) no-repeat center top / cover`, paddingTop: '100px' }}>
          <div className="container">
            <div className="row">
            <div className="col-md-6 mb-4 mb-md-4 mb-sm-4 mb-lg-0 d-flex flex-column align-items-center">
                <div className="footer-logo-wrap text-white d-flex flex-column align-items-center align-items-md-start">
                <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "250px",
          height: "250px",
          background: "#fff",
          borderRadius: "50%",
          margin: "0 auto",
          boxShadow: "0 0 30px rgba(0, 0, 0, 0.2)",
        }}
      >
                    <img src={footerLogo} alt="footer logo" style={{ width: '220px', zIndex: 1 }} />
                  </div>
                   {/* Policy Buttons */}
      <div className="d-flex flex-column flex-md-row mt-4">
        <Button
          variant="link"
          onClick={handlePrivacyPolicy}
          className="text-white mb-2 mb-md-0 me-md-3"
        >
          <i className="bi bi-shield-lock-fill me-2"></i> Chính sách bảo mật
        </Button>
        <Button variant="link" onClick={handleTermsOfService} className="text-white">
          <i className="bi bi-file-earmark-text-fill me-2"></i> Điều khoản sử dụng
        </Button>
      </div>
                </div>
              </div>

              <div className="col-md-6">
  <div className="footer-about-wrap text-white px-3 px-md-5">
    <h5 className="mb-3 text-white" style={{ fontWeight: "bold" }}>VỀ CHÚNG TÔI</h5>
    <ul className="list-unstyled support-list">
      <li className="mb-2 d-flex align-items-start">
        <Building className="me-2" size={22} style={{ flexShrink: 0 }} />
        <span style={{ fontSize: "16px", paddingLeft: "8px" }}>Công Ty TNHH Nối Group</span>
      </li>
      <li className="mb-2 d-flex align-items-start">
        <FileText className="me-2" size={22} style={{ flexShrink: 0 }} />
        <span style={{ fontSize: "16px", paddingLeft: "8px" }}>MST: 0318379051</span>
      </li>
      <li className="mb-2 d-flex align-items-start">
        <GeoAlt className="me-2" size={22} style={{ flexShrink: 0 }} />
        <span style={{ fontSize: "16px", paddingLeft: "8px" }}>Tầng 08, Toà nhà Pearl Plaza, Số 561A Điện Biên Phủ, P.25, Q.Bình Thạnh, TP.HCM</span>
      </li>
      <li className="mb-2 d-flex align-items-start">
        <Telephone className="me-2" size={26} style={{ flexShrink: 0 }} />
        <span style={{ fontSize: "16px", paddingLeft: "8px" }}>0866.428.869</span>
      </li>
      <li className="mb-2 d-flex align-items-start">
        <Globe className="me-2" size={22} style={{ flexShrink: 0 }} />
        <span style={{ fontSize: "16px", paddingLeft: "8px" }}>
          <a href="https://noi.dating/" style={{ color: "#fff" }}>www.noi.dating</a>
        </span>
      </li>
    </ul>

    
  </div>
</div>

            </div>
          </div>

          {/* Footer Bottom Section */}
          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <div className="copyright-wrap small-text text-center">
                    <p className="mb-0 text-white">© 2024 - Nối Group</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

     {/* Modal Chính sách bảo mật */}
{/* Modal Chính sách bảo mật */}
<Modal show={showPrivacyPolicy} onHide={handlePrivacyPolicy} centered  size="xl">
  <Modal.Header closeButton>
    <Modal.Title>Chính sách bảo mật</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <p>Kính gửi các thành viên APP IUDI</p>
    <p>
      Chúng tôi hiểu rằng bảo mật thông tin cá nhân là một trong những vấn đề quan trọng nhất mà bạn quan tâm khi tham gia vào App hẹn hò IUDI của Nối Group. Chính vì vậy, chúng tôi muốn bạn hoàn toàn an tâm và tin tưởng vào cam kết của chúng tôi về việc bảo vệ thông tin của bạn.
    </p>
    <p>
      Đầu tiên, chúng tôi cam kết tuân thủ các quy định và quyền riêng tư hiện hành để đảm bảo rằng thông tin cá nhân của bạn bao gồm:
    </p>
    <ul>
      <li>Số điện thoại/Số Zalo và hình chụp CMND/CCCD/Passport... được bảo mật một cách tốt nhất. Chúng tôi đảm bảo rằng các thông tin này của bạn sẽ chỉ được sử dụng cho mục đích xác thực, xác minh thông tin tài khoản và không bị tiết lộ cho bất kỳ bên thứ ba nào mà không có sự đồng ý của bạn.</li>
      <li>Hình ảnh cá nhân của bạn: Người ngoài không thể xem được, chỉ những thành viên đã tham gia và hoàn tất thủ tục đăng ký tài khoản tại APP IUDI mới có quyền xem.</li>
    </ul>
    <p>
      Thứ hai, chúng tôi đã triển khai các biện pháp bảo mật mạnh mẽ để đảm bảo an toàn cho thông tin cá nhân của bạn. Chúng tôi sử dụng các công nghệ tiên tiến để mã hóa dữ liệu và ngăn chặn truy cập trái phép vào hệ thống của chúng tôi.
    </p>
    <p>
      Thứ ba, chúng tôi luôn đặt mức độ ưu tiên cao với việc đảm bảo an toàn và sự riêng tư của thành viên. Chúng tôi cung cấp cho bạn các công cụ kiểm soát riêng tư để bạn có thể quyết định thông tin nào bạn muốn chia sẻ và với ai.
    </p>
    <p>
      Cuối cùng, chúng tôi có đội ngũ hỗ trợ chuyên nghiệp sẵn sàng giúp bạn trong mọi vấn đề liên quan đến bảo mật thông tin cá nhân. Nếu bạn có bất kỳ câu hỏi, lo ngại hoặc gặp vấn đề, hãy liên hệ với chúng tôi. Chúng tôi cam kết sẽ phản hồi nhanh chóng và giúp bạn giải quyết vấn đề.
    </p>
    <p>
      Vì vậy, hãy yên tâm và tin tưởng rằng chúng tôi sẽ bảo vệ an toàn thông tin cá nhân của bạn trên App hẹn hò IUDI. Chúng tôi hiểu rằng sự tin tưởng là yếu tố quan trọng để bạn có thể tận hưởng trải nghiệm hẹn hò trực tuyến một cách an toàn và hiệu quả tại APP IUDI.
    </p>
    <p>Cảm ơn bạn đã tin tưởng và sử dụng dịch vụ của chúng tôi!</p>
    <hr />
    <h5>VỀ CHÚNG TÔI</h5>
    <p>
      Công Ty TNHH Nối Group<br />
      MST: 0318379051<br />
      Địa chỉ: Tầng 08, Toà nhà Pearl Plaza, Số 561A Điện Biên Phủ, P.25, Q.Bình Thạnh, TP.HCM<br />
      Điện thoại: 0866.428.869<br />
      Website: <a href="https://noi.dating" target="_blank" rel="noopener noreferrer">www.noi.dating</a><br />
      Email: info.noidating@gmail.com
    </p>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handlePrivacyPolicy}>Đóng</Button>
  </Modal.Footer>
</Modal>

      {/* Modal Điều khoản sử dụng */}
      {/* Modal Điều khoản sử dụng */}
      <Modal show={showTermsOfService} onHide={handleTermsOfService} centered size="xl" dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Điều khoản sử dụng</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>(Hiệu lực từ tháng 09/2024)</p>
          <p>1. Giới thiệu</p>
          <p>
            Cảm ơn bạn đã tham gia vào App hẹn hò - iudi, một ứng dụng hẹn hò online nghiêm túc với cam kết 100% Thành viên và tài khoản thật. Khi tham gia ứng dụng, bạn đồng ý tuân thủ các điều khoản sử dụng dưới đây.
          </p>
          <p>2. Đăng ký và sử dụng tài khoản</p>
          <ul>
            <li>iudi yêu cầu người dùng sử dụng tài khoản Zalo cá nhân để đăng ký và tạo hồ sơ. App không phải là một mạng xã hội và chỉ sử dụng Zalo để kết nối an toàn, bảo mật.</li>
            <li>Các thành viên sẽ chỉ được nhắn tin, trò chuyện qua Zalo cá nhân khi cả hai bên cùng đồng ý. iudi không can thiệp và không chia sẻ số điện thoại hay tài khoản Zalo của thành viên với bên thứ ba.</li>
          </ul>
          <p>3. Xác minh và bảo mật thông tin cá nhân</p>
          <ul>
            <li>Để đảm bảo tính xác thực của các tài khoản, iudi sẽ dựa trên giấy tờ tùy thân (CMND/CCCD) để xác minh thông tin như: Họ tên, năm sinh, và quê quán của người dùng.</li>
            <li>Chúng tôi cam kết bảo mật thông tin giấy tờ của người dùng và chỉ sử dụng với mục đích xác minh tài khoản. Thông tin này sẽ không được cung cấp cho bên thứ ba nếu chưa có sự đồng ý của người dùng.</li>
          </ul>
          <p>4. Quy định về hồ sơ cá nhân</p>
          <ul>
            <li>Hồ sơ do thành viên chia sẻ và hoàn toàn chịu trách nhiệm với thông tin cá nhân mà mình cung cấp.</li>
            <li>Nội dung hồ sơ sẽ được kiểm soát để đảm bảo tuân thủ các tiêu chuẩn văn hóa, đạo đức, không chứa nội dung thô tục, vi phạm pháp luật Việt Nam.</li>
            <li>Thành viên không thể tự chỉnh sửa hồ sơ sau khi được duyệt. Nếu có nhu cầu chỉnh sửa, vui lòng liên hệ Ban Quản Trị (BQT) để được hỗ trợ.</li>

          </ul>
          <p>5. Quyền hạn và trách nhiệm của iudi</p>
          <ul>
          <li>iudi có quyền từ chối hoặc gỡ bỏ nội dung không phù hợp trên hồ sơ thành viên nếu vi phạm các tiêu chuẩn đã quy định.
          </li> 
          <li>Chúng tôi cam kết đảm bảo tính bảo mật và xác thực của tài khoản người dùng. Tuy nhiên, iudi không chịu trách nhiệm với các vấn đề phát sinh từ quá trình giao lưu, kết nối giữa hai bên thông qua Zalo.
         
          </li>
          </ul>
          <p>6. Quy định về bảo mật và quyền riêng tư</p>
          <ul>
          <li>
          Mọi thông tin cá nhân, tài khoản Zalo và giấy tờ tùy thân của thành viên đều được bảo mật tuyệt đối.
          </li>
          <li>Thông tin cá nhân chỉ được sử dụng cho mục đích xác thực tài khoản và không chia sẻ với bên thứ ba nếu chưa có sự đồng ý từ người dùng.
          </li></ul>
          <p>7. Điều khoản miễn trừ trách nhiệm</p>
          <ul>
          <li>
            iudi không chịu trách nhiệm trước các rủi ro hay vấn đề phát sinh từ sự kết nối tự nguyện giữa hai bên, bao gồm các hành vi chia sẻ thông tin, giao tiếp, và gặp gỡ.
          </li>
          <li>Người dùng tự chịu trách nhiệm về tính xác thực của các thông tin và tương tác với các thành viên khác khi kết nối với nhau qua tài khoàn Zalo của mình.
          </li>
          </ul>
          <p>8. Thông tin liên hệ</p>
          <p>
            Công Ty TNHH Nối Group<br />
            MST: 0318379051<br />
            Địa chỉ: Tầng 08, Toà nhà Pearl Plaza, Số 561A Điện Biên Phủ, P.25, Q.Bình Thạnh, TP.HCM<br />
            Điện thoại: 0866.428.869<br />
            Website: <a href="https://noi.dating" target="_blank" rel="noopener noreferrer">www.noi.dating</a><br />
            Email: info.noidating@gmail.com
          </p>
          <p>
            Các điều khoản này có thể được cập nhật theo thời gian để phù hợp với quy định pháp luật và điều kiện thực tế.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleTermsOfService}>Đóng</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Footer;

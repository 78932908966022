// import React, { useEffect, useState } from "react";
// import NavLinks from "./NavLinks";
// import CloseBtn from "../assets/images/shapes/close-1-1.png";

// const MobileMenu = () => {
//   const [hasMounted, setHasMounted] = useState(false);
//   const mobileMenu = () => {
//     document
//       .querySelector(".side-menu__toggler")
//       .addEventListener("click", function (e) {
//         document.querySelector(".side-menu__block").classList.toggle("active");
//         e.preventDefault();
//       });

//     //Close Mobile Menu
//     let sideMenuCloser = document.querySelectorAll(
//       ".side-menu__close-btn, .side-menu__block-overlay"
//     );

//     sideMenuCloser.forEach((sideMenuCloserBtn) => {
//       sideMenuCloserBtn.addEventListener("click", function (e) {
//         document.querySelector(".side-menu__block").classList.remove("active");
//         e.preventDefault();
//       });
//     });
//   };
//   useEffect(() => {
//     if (typeof window !== "undefined") {
//       mobileMenu();
//       setHasMounted(true);
//       return () => {
//         mobileMenu();
//       };
//     }
//   }, [hasMounted]);

//   if (!hasMounted) {
//     return null;
//   }

//   return (
//     <div className="side-menu__block">
//       <div className="side-menu__block-overlay custom-cursor__overlay">
//         <div className="cursor"></div>
//         <div className="cursor-follower"></div>
//       </div>
//       <div className="side-menu__block-inner ">
//         <div className="side-menu__top justify-content-end">
//           <a href="#none" className="side-menu__toggler side-menu__close-btn">
//             <img src={CloseBtn} alt="awesome post" />
//           </a>
//         </div>

//         <nav className="mobile-nav__container">
//           <NavLinks />
//         </nav>
//         <div className="side-menu__sep"></div>
//         <div className="side-menu__content">
//           {/* <p>
//             Lorem Ipsum is simply dummy text the printing and setting industry.
//             Lorm Ipsum has been the industry's stanard dummy text ever.
//           </p>
//           <p>
//             <a href="mailto:needhelp@apton.com">needhelp@apton.com</a> <br />
//             <a href="tel:888-999-0000">888 999 0000</a>
//           </p>
//           <div className="side-menu__social">
//             <a href="#none">
//               <i className="fab fa-facebook-square"></i>
//             </a>
//             <a href="#none">
//               <i className="fab fa-twitter"></i>
//             </a>
//             <a href="#none">
//               <i className="fab fa-instagram"></i>
//             </a>
//             <a href="#none">
//               <i className="fab fa-pinterest-p"></i>
//             </a> */}
//           {/* </div> */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MobileMenu;



// import React, { useEffect, useState } from "react";
// import CloseBtn from "../assets/images/shapes/close-1-1.png";

// const MobileMenu = () => {
//   const [hasMounted, setHasMounted] = useState(false);

//   // Hàm để mở/đóng menu
//   const toggleMenu = (isOpen) => {
//     const menu = document.querySelector(".side-menu__block");
//     if (menu) {
//       if (isOpen) {
//         menu.classList.add("active");
//       } else {
//         menu.classList.remove("active");
//       }
//     }
//   };

//   // Hàm để xử lý việc nhấp vào liên kết menu
//   const handleMenuClick = (e, targetId) => {
//     e.preventDefault();
//     // Cuộn đến phần tương ứng trên trang
//     const targetElement = document.getElementById(targetId);
//     if (targetElement) {
//       targetElement.scrollIntoView({ behavior: "smooth" });
//     }
//     // Đóng menu sau khi chuyển hướng
//     toggleMenu(false);
//   };

//   useEffect(() => {
//     if (typeof window !== "undefined") {
//       setHasMounted(true);
//       // Thêm sự kiện cho việc mở menu
//       document
//         .querySelector(".side-menu__toggler")
//         .addEventListener("click", (e) => {
//           toggleMenu(true);
//           e.preventDefault();
//         });

//       // Đóng menu khi nhấp vào các nút đóng
//       let sideMenuCloser = document.querySelectorAll(
//         ".side-menu__close-btn, .side-menu__block-overlay"
//       );
//       sideMenuCloser.forEach((sideMenuCloserBtn) => {
//         sideMenuCloserBtn.addEventListener("click", (e) => {
//           toggleMenu(false);
//           e.preventDefault();
//         });
//       });

//       return () => {
//         // Xóa sự kiện khi component unmount
//         sideMenuCloser.forEach((sideMenuCloserBtn) => {
//           sideMenuCloserBtn.removeEventListener("click", toggleMenu);
//         });
//       };
//     }
//   }, [hasMounted]);

//   if (!hasMounted) {
//     return null;
//   }

//   return (
//     <div className="side-menu__block">
//       <div className="side-menu__block-overlay custom-cursor__overlay">
//         <div className="cursor"></div>
//         <div className="cursor-follower"></div>
//       </div>
//       <div className="side-menu__block-inner ">
//         <div className="side-menu__top justify-content-end">
//           <a href="#none" className="side-menu__toggler side-menu__close-btn">
//             <img src={CloseBtn} alt="close menu" />
//           </a>
//         </div>

//         <nav className="mobile-nav__container">
//           {/* Sử dụng hàm để xử lý nhấp vào liên kết */}
//           <ul>
//             <li>
//               <a href="#home" onClick={(e) => handleMenuClick(e, "home")}>
//                 Trang chủ
//               </a>
//             </li>
//             <li>
//               <a href="#about" onClick={(e) => handleMenuClick(e, "about")}>
//                 Giới thiệu
//               </a>
//             </li>
//             <li>
//               <a href="#services" onClick={(e) => handleMenuClick(e, "services")}>
//                 Hướng dẫn
//               </a>
//             </li>
//             <li>
//               <a href="#pricing" onClick={(e) => handleMenuClick(e, "pricing")}>
//                 Gói phí
//               </a>
//             </li>
//             <li>
//               <a href="#gallery" onClick={(e) => handleMenuClick(e, "gallery")}>
//                 Hình ảnh
//               </a>
//             </li>
//           </ul>
//         </nav>

//         <div className="side-menu__sep"></div>
//         <div className="side-menu__content">
//           {/* Nội dung thêm nếu cần */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MobileMenu;



import React, { useEffect, useState } from "react";
import NavLinks from "./NavLinks";
import CloseBtn from "../assets/images/shapes/close-1-1.png";

const MobileMenu = () => {
  const [hasMounted, setHasMounted] = useState(false);

  // Hàm đóng menu
  const closeMobileMenu = () => {
    document.querySelector(".side-menu__block").classList.remove("active");
  };

  const mobileMenu = () => {
    document.querySelector(".side-menu__toggler").addEventListener("click", function (e) {
      document.querySelector(".side-menu__block").classList.toggle("active");
      e.preventDefault();
    });

    // Đóng menu khi nhấn nút đóng hoặc overlay
    let sideMenuCloser = document.querySelectorAll(
      ".side-menu__close-btn, .side-menu__block-overlay, .menu-link"
    );

    sideMenuCloser.forEach((sideMenuCloserBtn) => {
      sideMenuCloserBtn.addEventListener("click", function (e) {
        closeMobileMenu();
        e.preventDefault();
      });
    });
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      mobileMenu();
      setHasMounted(true);
      return () => {
        mobileMenu();
      };
    }
  }, [hasMounted]);

  if (!hasMounted) {
    return null;
  }

  return (
    <div className="side-menu__block" >
      
      <div className="side-menu__block-overlay custom-cursor__overlay">
        <div className="cursor"></div>
        <div className="cursor-follower"></div>
      </div>
      <div className="side-menu__block-inner">
        <div className="side-menu__top justify-content-end">
          <a href="#none" className="side-menu__toggler side-menu__close-btn">
            <img src={CloseBtn} alt="awesome post" />
          </a>
        </div>

        <nav className="mobile-nav__container">
          <NavLinks linkClass="menu-link" />
        </nav>
        <div className="side-menu__sep"></div>
        <div className="side-menu__content">
          {/* Nội dung bổ sung nếu cần */}
          <a href="https://zalo.me/s/335985251439518648" style={{ display: 'block', marginBottom: '10px' }}>
  <span>Tải ứng dụng</span>
</a>
<a href="https://www.noi.dating/" style={{ display: 'block' }}>
  <span>Nối Group</span>
</a>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;


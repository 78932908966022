import React, { useState, useEffect } from "react";
import NavLinks from "./NavLinks";

import LogoImage from "../assets/images/logo-1-1.png";
import {  Button } from "react-bootstrap";
const HeaderHome = (props) => {
  const [sticky, setSticky] = useState(false);
  const handleButtonClick = () => {
    window.location.href = 'https://www.noi.dating'; // Redirects to the specified URL
  };
  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sticky]);

  return (
    <header
      className={`site-header-one stricky  ${props.extraClassName} ${
        sticky === true ? "stricky-fixed stricked-menu" : " "
      }`}
    >
      
      <div className="container-fluid">
        <div className="site-header-one__logo">
          <a href="/">
            <img src={LogoImage} width="169px"  alt="awesome post" />
          </a>
          <span className="side-menu__toggler">
            <i className="fa fa-bars"></i>
          </span>
        </div>
        <div className="main-nav__main-navigation">
          <NavLinks />
        </div>
        <div className="main-nav__right">
   
<Button style={{ marginLeft: '15px' }}
        variant="primary" 
        className="thm-btn cta-one__btn" 
        onClick={handleButtonClick} // Handle click to redirect
      >
        <span>Nối Group</span>
      </Button>
<a
  href="https://zalo.me/s/335985251439518648"
  className={`thm-btn ${props.btnClass}`}
  style={{ marginLeft: '15px' }} // Thêm khoảng cách bên trái cho nút
>
  <span>Tải ứng dụng</span>
</a>
        </div>
      </div>
    </header>
  );
};

export default HeaderHome;
